import React from 'react';
import { Helmet } from 'react-helmet';

const SEOMetaTags = ({ title, description, keywords, canonicalUrl, ogImage }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
    <link rel="canonical" href={canonicalUrl} />
    
    {/* Open Graph / Facebook */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content={canonicalUrl} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={ogImage} />
    <meta property="og:site_name" content="Codelamda" />
    <meta property="og:locale" content="en_US" />

    {/* Twitter */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:url" content={canonicalUrl} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={ogImage} />
    <meta name="twitter:creator" content="@codelamda" />

    {/* Additional Meta Tags */}
    <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="language" content="English" />
    <meta name="author" content="Codelamda" />
    <meta name="geo.region" content="US" />
    <meta name="geo.placename" content="Silicon Valley" />

    {/* JSON-LD Structured Data */}
    <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Codelamda",
          "url": "${canonicalUrl}",
          "logo": "${ogImage}",
          "description": "${description}",
          "sameAs": [
            "https://www.linkedin.com/company/codelamda",
            "https://twitter.com/codelamda",
            "https://github.com/codelamda"
          ],
          "address": {
            "@type": "PostalAddress",
            "addressCountry": "US",
            "addressRegion": "CA"
          },
          "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "customer service",
            "email": "contact@codelamda.com"
          }
        }
      `}
    </script>
  </Helmet>
);

export default SEOMetaTags;