import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { pageview } from './analytics';

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
      pageview(location.pathname + location.search);
    }
  }, [location]);

  return null; // This component doesn't render anything
};

export default RouteChangeTracker;
