import React, { useEffect, Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { initGA } from "./utils/analytics";
import { reportWebVitals, sendToAnalytics } from "./utils/performance";
import SEOMetaTags from "./components/SEOMetaTags";
import ResourcePreload from "./components/common/ResourcePreload";
import RouteChangeTracker from "./utils/RouteChangeTracker";
import LoadingSpinner from "./components/common/LoadingSpinner";

// Lazy load components
const BlogDetails = React.lazy(() => import("./components/blog/BlogDetails"));
const Blogs = React.lazy(() => import("./components/blog/Blogs"));
const CareerDetails = React.lazy(() => import("./components/careerDetails"));
const Navbar = React.lazy(() => import("./components/common/Navbar"));
const AboutPage = React.lazy(() => import("./pages/AboutPage"));
const Career = React.lazy(() => import("./pages/Career"));
const ContactPage = React.lazy(() => import("./pages/ContactPage"));
const Home = React.lazy(() => import("./pages/Home"));
const ServicePage = React.lazy(() => import("./pages/ServicePage"));
const PortfolioPage = React.lazy(() => import("./pages/PortfolioPage"));
const Projectdetails = React.lazy(() => import("./pages/Projectdetails"));

const App = () => {
  useEffect(() => {
    // Initialize Google Analytics
    if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
      initGA(process.env.REACT_APP_GA_MEASUREMENT_ID);
    }

    // Register service worker
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/serviceWorker.js')
          .then(registration => {
            console.log('ServiceWorker registration successful');
          })
          .catch(err => {
            console.log('ServiceWorker registration failed: ', err);
          });
      });
    }

    // Initialize performance monitoring
    reportWebVitals(sendToAnalytics);
  }, []);

  return (
    <Router>
      <SEOMetaTags
        title="Codelamda | Digital Transformation & Cloud Solutions | AWS, AI/ML, DevOps"
        description="Accelerate your digital transformation journey with Codelamda's enterprise-grade cloud solutions. Experts in AWS, AI/ML, DevOps automation, and serverless architecture. Transform your business with cutting-edge technology solutions."
        keywords="digital transformation, enterprise cloud solutions, AWS consulting, AI/ML implementation, DevOps automation, serverless architecture, cloud migration, digital innovation, IT modernization, cloud-native development, microservices, containerization, Kubernetes orchestration, CI/CD pipeline, business process automation"
        canonicalUrl="https://www.codelamda.com"
        ogImage="https://www.codelamda.com/logo.png"
      />
      <ResourcePreload />
      <RouteChangeTracker />
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route path="/" element={<Navbar />}>
            <Route index element={<Home />} />
            <Route path="service" element={<ServicePage />} />
            <Route path="career" element={<Career />} />
            <Route path="careerDetails" element={<CareerDetails />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="blogs" element={<Blogs />} />
            <Route path="/portfolio" element={<PortfolioPage />} />
            <Route path="/portfolio/:portfolioname/:id" element={<Projectdetails />} />
            <Route path="/blog/:source/:id/:title" element={<BlogDetails />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;