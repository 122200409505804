import React from 'react';
import { Helmet } from 'react-helmet';

const ResourcePreload = () => {
  const criticalResources = [
    // Add your logo and other critical images
    { href: '/logo192.png', as: 'image' },
    { href: '/logo512.png', as: 'image' },
    // Add your fonts
    { href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap', as: 'style' },
    // Add your critical CSS
    { href: '/static/css/main.css', as: 'style' },
  ];

  return (
    <Helmet>
      {criticalResources.map((resource, index) => (
        <link 
          key={index}
          rel="preload"
          href={resource.href}
          as={resource.as}
          crossOrigin={resource.as === 'font' ? 'anonymous' : undefined}
        />
      ))}
      {/* DNS Prefetch for external resources */}
      <link rel="dns-prefetch" href="https://www.google-analytics.com" />
      <link rel="dns-prefetch" href="https://fonts.googleapis.com" />
      
      {/* Preconnect for critical third-party origins */}
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      <link rel="preconnect" href="https://www.google-analytics.com" crossOrigin="anonymous" />
    </Helmet>
  );
};

export default ResourcePreload;
