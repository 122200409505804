import { getCLS, getFID, getLCP } from 'web-vitals';

export const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    // Cumulative Layout Shift
    getCLS(onPerfEntry);
    // First Input Delay
    getFID(onPerfEntry);
    // Largest Contentful Paint
    getLCP(onPerfEntry);
  }
};

// Send performance metrics to Google Analytics
export const sendToAnalytics = ({ name, delta, id }) => {
  if (window.gtag) {
    window.gtag('event', name, {
      event_category: 'Web Vitals',
      event_label: id,
      value: Math.round(name === 'CLS' ? delta * 1000 : delta),
      non_interaction: true,
    });
  }
};
